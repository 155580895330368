:root {

    /* page */
    --about-background-color: #3c4856;
    --about-top-spacing: 8vh;
    --about-bot-spacing: 10vh;

    /* intersection animations */
    --about-intersection-animation-speed: 1.5s;
    --about-intersection-animation-delay: .7s;


    /* two sides section */
    --about-two-sides-section-border-color: #3f4349;
    --about-two-sides-section-border-width: 1px;
    --about-two-sides-section-text-block-background-color: #141416;
    --about-two-sides-section-text-block-title-font-color: #ffffff;
    --about-two-sides-section-text-block-title-font-size: 5.5vh;
    --about-two-sides-section-text-block-text-font-color: #ffffff;
    --about-two-sides-section-text-block-text-font-size: 2.5vh;
    --about-two-sides-section-text-block-text-line-height: 1.8rem;
    --about-two-sides-section-text-block-animation-delay: .5s;
    --about-two-sides-section-text-block-animation-travel-distance: 5vh;

    /* two sides section TABLET/PHONE */
    --about-two-sides-section-text-block-background-color-phone: rgba(20, 20, 22, 0.6);
    --about-two-sides-section-text-block-margin-top-bot-phone: auto;
    --about-two-sides-section-text-block-width-phone: 65%;
    --about-two-sides-section-text-block-title-font-color-phone: #ffffff;
    --about-two-sides-section-text-block-title-font-size-phone: 5.5vh;
    --about-two-sides-section-text-block-text-font-color-phone: #ffffff;
    --about-two-sides-section-text-block-text-font-size-phone: 2vh;
    --about-two-sides-section-text-block-text-line-height-phone: 1.8rem;


    /* img background section */
    --about-img-background-section-overlay-color: rgba(0, 0, 0, 0.59);
    --about-img-background-section-margin-top: 10vh;
    --about-img-background-section-titles-spacing: 7vh;
    --about-img-background-section-titles-color: #ffffff;
    --about-img-background-section-titles-font-size: 3.5vh;

    /* img background section PHONE/TABLET */
    --about-img-background-section-width-mobile: 75vw;
    --about-img-background-section-margin-top-mobile: 10vh;
    --about-img-background-section-titles-spacing-mobile: 3vh;
    --about-img-background-section-titles-font-size-mobile: 3.5vh;

    /* img background left side */
    --about-img-background-section-left-text-color: #ffffff;
    --about-img-background-section-left-text-font-size: 2.75vh;
    --about-img-background-section-left-text-line-height: 2.5rem;
    --about-img-background-section-left-text-letters-spacing: .08rem;
    --about-img-background-section-left-text-animation-delay: .5s;
    --about-img-background-section-left-text-animation-travel-distance: -5vw;

    /* img background left side PHONE/TABLET */
    --about-img-background-section-left-text-font-size-mobile: 2.25vh;
    --about-img-background-section-left-text-line-height-mobile: 2.5rem;
    --about-img-background-section-left-text-letters-spacing-mobile: .08rem;

    /* img background inner blocks */
    --about-img-background-section-right-inner-blocks-spacing: 4vh;
    --about-img-background-section-right-inner-animation-delay: .7s;
    --about-img-background-section-right-inner-travel-distance: 5vw;

    /* img background inner blocks PHONE/TABLET */
    --about-img-background-section-right-inner-blocks-spacing-mobile: 4.5vh;

    /* img background inner blocks border */
    --about-img-background-section-right-inner-block-border-height: 85%;
    --about-img-background-section-right-inner-block-border-width: 2px;
    --about-img-background-section-right-inner-block-border-color: #af6118;
    --about-img-background-section-right-inner-block-border-spacing: 1vw;

    /* img background inner blocks border PHONE/TABLET */
    --about-img-background-section-right-inner-block-border-spacing-mobile: 2.5vw;

    /* img background inner blocks titles */
    --about-img-background-section-right-inner-block-title-color: #ffffff;
    --about-img-background-section-right-inner-block-title-font-size: 2.5vh;
    --about-img-background-section-right-inner-block-title-line-height: 1rem;
    --about-img-background-section-right-inner-block-title-letters-spacing: .08rem;
    --about-img-background-section-right-inner-block-title-bottom-spacing: 1.5vh;

    /* img background inner blocks titles PHONE/TABLET */
    --about-img-background-section-right-inner-block-title-font-size-mobile: 2.5vh;
    --about-img-background-section-right-inner-block-title-line-height-mobile: 3vh;
    --about-img-background-section-right-inner-block-title-letters-spacing-mobile: .08rem;
    --about-img-background-section-right-inner-block-title-bottom-spacing-mobile: 1.5vh;

    /* img background inner blocks text */
    --about-img-background-section-right-inner-block-text-color: #ffffff;
    --about-img-background-section-right-inner-block-text-font-size: 1.75vh;
    --about-img-background-section-right-inner-block-text-line-height: 1.7rem;
    --about-img-background-section-right-inner-block-text-letters-spacing: .04rem;

    /* img background inner blocks text PHONE/TABLET */
    --about-img-background-section-right-inner-block-text-font-size-mobile: 2vh;
    --about-img-background-section-right-inner-block-text-line-height-mobile: 1.8rem;
    --about-img-background-section-right-inner-block-text-letters-spacing-mobile: .04rem;


    /* team section */

    /* title */
    --about-team-title-top-margin: 10vh;
    --about-team-title-color: #ffffff;
    --about-team-title-font-size: 3rem;
    --about-team-title-line-height: .1rem;
    --about-team-title-letters-spacing: -.08rem;
    --about-team-company-name-color: #ffffff;
    --about-team-company-name-font-size: 1.2rem;
    --about-team-company-name-line-height: .1rem;
    --about-team-company-name-letters-spacing: .05rem;

    /* members blocks */
    --about-team-members-line-top-margin: 10vh;
    --about-team-members-vertical-line-width: 2px;
    --about-team-members-vertical-line-height: 100%;
    --about-team-members-vertical-line-color: #af6118;
    --about-team-members-block-lr-margin: 2vw;
    --about-team-members-block-transition-delay: .5s;

    /* members blocks img */
    --about-team-members-img-border-radius: 15%;
    --about-team-members-img-right-margin: 2.5rem;
    --about-team-members-img-left-margin: 1.5rem;

    /* members blocks name */
    --about-team-members-name-color: #ffffff;
    --about-team-members-name-font-size: 1.7rem;
    --about-team-members-name-line-height: .5rem;
    --about-team-members-name-letters-spacing: .05rem;

    /* spacing */
    --about-team-members-description-spacing: 0;

    /* members blocks position */
    --about-team-members-position-color: #a2a2a2;
    --about-team-members-position-font-size: 1.1rem;
    --about-team-members-position-line-height: 1.5rem;
    --about-team-members-position-letters-spacing: .01rem;
}

/* general */

.about {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    background-color: var(--about-background-color);
    scroll-behavior: smooth;
}

.about-top-spacing {
    display: flex;
    height: var(--about-top-spacing);
}

/* normal */
@media screen and (min-width: 1300px){
    .about-top-spacing {
        height: var(--about-top-spacing);
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .about-top-spacing {
        height: calc(var(--header-max-height) + var(--nav-menu-padding-phone) / 2);
    }
}

.about-bot-spacing {
    display: flex;
    height: var(--about-bot-spacing);
}

.about-hidden {
    opacity: 0;
    transition: all var(--about-intersection-animation-speed);
    transition-delay: var(--about-intersection-animation-delay);
}

.about-show {
    opacity: 1 !important;
}

/* END */

/* two sides section */

.about-two-sides-section {
    display: flex;
    position: relative;
    height: auto;
}

.about-two-sides-section-img-container {
    display: flex;
    min-width: 40vw;
    overflow: hidden;
    border-width: 0 var(--about-two-sides-section-border-width) 0 0;
    border-style: solid;
    border-color: var(--about-two-sides-section-border-color);
}

.about-two-sides-section-img-container img {
    width: 60vw;
}

.about-two-sides-section-text-block-section {
    width: 100%;
    background-color: var(--about-two-sides-section-text-block-background-color);
    align-items: center;
}

.about-two-sides-section-text-block-title {
    display: flex;
    writing-mode: vertical-lr;
    rotate: 180deg;
    justify-self: center;
    font-size: var(--about-two-sides-section-text-block-title-font-size);
    color: var(--about-two-sides-section-text-block-title-font-color);
    margin-left: .5rem;
}

.about-two-sides-section-text-block-text-container {
    display: flex;
}

.about-two-sides-section-text-block-text.about-show {
    transform: translateY(0);
}

/* normal */
@media screen and (min-width: 1300px){
    .about-two-sides-section-img-container img {
        width: 60vw;
        transform: translateX(-10vw); /* bind with scroll */
    }

    .about-two-sides-section-text-block-section {
        display: flex;
    }

    .about-two-sides-section-text-block-title {
        font-size: var(--about-two-sides-section-text-block-title-font-size);
        color: var(--about-two-sides-section-text-block-title-font-color);
    }

    .about-two-sides-section-text-block-text-container {
        max-width: 60%;
        height: 100%;
        margin: auto;
        line-height: var(--about-two-sides-section-text-block-text-line-height);
    }

    .about-two-sides-section-text-block-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateY(0) !important;
    }

    .about-two-sides-section-text-block-text p {
        text-align: justify;
        font-size: var(--about-two-sides-section-text-block-text-font-size);
        color: var(--about-two-sides-section-text-block-text-font-color);
    }

    .about-two-sides-section-text-block-text.about-hidden {
        transition-delay: calc(var(--about-intersection-animation-delay) + var(--about-two-sides-section-text-block-animation-delay));
        transform: translateY(var(--about-two-sides-section-text-block-animation-travel-distance));
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .about-two-sides-section {
        align-items: center;
        position: relative;
    }

    .about-two-sides-section-text-block-title {
        font-size: var(--about-two-sides-section-text-block-title-font-size-phone);
        color: var(--about-two-sides-section-text-block-title-font-color-phone);
    }

    .about-two-sides-section-text-block-text-container {
        height: 100%;
        margin: var(--about-two-sides-section-text-block-margin-top-bot-phone) auto;
        max-width: var(--about-two-sides-section-text-block-width-phone);
        line-height: var(--about-two-sides-section-text-block-text-line-height-phone);
    }

    .about-two-sides-section-text-block-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        transform: none !important;
    }

    .about-two-sides-section-text-block-text p {
        text-align: justify;
        font-size: var(--about-two-sides-section-text-block-text-font-size-phone);
        color: var(--about-two-sides-section-text-block-text-font-color-phone);
    }

    .about-two-sides-section-text-block-text.about-hidden {
        transition-delay: calc(var(--about-intersection-animation-delay) +  var(--about-two-sides-section-text-block-animation-delay));
        transform: translateY(var(--about-two-sides-section-text-block-animation-travel-distance));
    }

    .about-two-sides-section-text-block-section {
        background-color: var(--about-two-sides-section-text-block-background-color-phone);
        display: flex;
        position: relative;
    }

    .about-two-sides-section-img-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .about-two-sides-section-img-container img {
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        object-fit: cover;
    }
}

/* END */

/* img background section */

.about-img-background-section {
    display: flex;
    height: auto;
    position: relative;
}

.about-img-background-img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.about-img-background-img-container img {
    width: 200%;
    height: auto;
    top: 0;
    left: 0;
    min-height: 100%;
    position: absolute;
    object-fit: cover;
}

.about-img-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--about-img-background-section-overlay-color);
    width: 100%;
    height: 100%;

}

.about-img-background-content-container {
    display: flex;
    flex: 1;
}

.about-img-background-block-title {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    color: var(--about-img-background-section-titles-color);
}

.about-img-background-left-block-text {
    margin: auto;
    color: var(--about-img-background-section-left-text-color);
    transition: all var(--about-intersection-animation-speed);
}

.about-img-background-right-block {
    max-height: 100%;
}

.about-img-background-right-inner-block-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    transition-delay: var(--about-img-background-section-left-text-animation-delay);
    transition: all var(--about-intersection-animation-speed);
}

.about-img-background-right-inner-block-title {
    color: var(--about-img-background-section-right-inner-block-title-color);
}

.about-img-background-right-inner-block-text {
    color: var(--about-img-background-section-right-inner-block-text-color);
}

/* normal */
@media screen and (min-width: 1300px){
    .about-img-background-content-container {
        margin-top: var(--about-img-background-section-margin-top);
    }

    .about-img-background-img-container img {
        max-width: 150%;
    }

    .about-img-background-left-block {
        max-width: 50vw;
        max-height: 100%;
    }

    .about-img-background-block-title {
        margin-bottom: var(--about-img-background-section-titles-spacing);
        font-size: var(--about-img-background-section-titles-font-size);
        max-width: 50%;
    }

    .about-img-background-left-block-text {
        max-width: 50%;
        font-size: var(--about-img-background-section-left-text-font-size);
        line-height: var(--about-img-background-section-left-text-line-height);
        letter-spacing: var(--about-img-background-section-left-text-letters-spacing);
    }

    .about-img-background-right-block {
        max-width: 50vw;
    }

    .about-img-background-right-inner-block-container {
        max-width: 50%;
        margin-bottom: var(--about-img-background-section-right-inner-blocks-spacing);
    }

    .about-img-background-right-inner-block-title {
        font-size: var(--about-img-background-section-right-inner-block-title-font-size);
        line-height: var(--about-img-background-section-right-inner-block-title-line-height);
        letter-spacing: var(--about-img-background-section-right-inner-block-title-letters-spacing);
        margin-bottom: var(--about-img-background-section-right-inner-block-title-bottom-spacing);
        margin-left: var(--about-img-background-section-right-inner-block-border-spacing);
    }

    .about-img-background-right-inner-block-text {
        font-size: var(--about-img-background-section-right-inner-block-text-font-size);
        line-height: var(--about-img-background-section-right-inner-block-text-line-height);
        letter-spacing: var(--about-img-background-section-right-inner-block-text-letters-spacing);
        margin-left: var(--about-img-background-section-right-inner-block-border-spacing);
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){

    .about-img-background-content-container {
        margin: var(--about-img-background-section-margin-top-mobile) 0;
        flex-direction: column;
    }

    .about-img-background-left-block {
        margin: 0 auto calc(var(--about-img-background-section-titles-spacing-mobile) * 2);
        width: var(--about-img-background-section-width-mobile);
    }

    .about-img-background-block-title {
        margin-bottom: var(--about-img-background-section-titles-spacing-mobile);
        font-size: var(--about-img-background-section-titles-font-size-mobile);
        text-align: center;
        max-width: 100%;
    }

    .about-img-background-left-block-text {
        width: 100%;
        text-align: justify;
        font-size: var(--about-img-background-section-left-text-font-size-mobile);
        line-height: var(--about-img-background-section-left-text-line-height-mobile);
        letter-spacing: var(--about-img-background-section-left-text-letters-spacing-mobile);
    }

    .about-img-background-right-block {
        margin: 0 auto;
        max-width: var(--about-img-background-section-width-mobile);
    }

    .about-img-background-right-inner-block-container {
        max-width: 100%;
        margin-bottom: var(--about-img-background-section-right-inner-blocks-spacing-mobile);
    }

    .about-img-background-right-inner-block-text {
        text-align: justify;
    }

    .about-img-background-right-inner-block-title {
        font-size: var(--about-img-background-section-right-inner-block-title-font-size-mobile);
        line-height: var(--about-img-background-section-right-inner-block-title-line-height-mobile);
        letter-spacing: var(--about-img-background-section-right-inner-block-title-letters-spacing-mobile);
        margin-bottom: var(--about-img-background-section-right-inner-block-title-bottom-spacing-mobile);
        margin-left: var(--about-img-background-section-right-inner-block-border-spacing-mobile);
    }
    .about-img-background-right-inner-block-text {
        font-size: var(--about-img-background-section-right-inner-block-text-font-size-mobile);
        line-height: var(--about-img-background-section-right-inner-block-text-line-height-mobile);
        letter-spacing: var(--about-img-background-section-right-inner-block-text-letters-spacing-mobile);
        margin-left: var(--about-img-background-section-right-inner-block-border-spacing-mobile);
    }
}

.about-img-background-left-block.about-hidden .about-img-background-left-block-text {
    transition-delay: calc(var(--about-intersection-animation-delay) + var(--about-img-background-section-left-text-animation-delay));
    transform: translateX(-5vw);
}

.about-img-background-left-block.about-show .about-img-background-left-block-text {
    transform: translateX(0);
}

.about-img-background-right-inner-block-container:nth-child(2) {
    transition-delay: calc(var(--about-img-background-section-left-text-animation-delay) * 2);
}

.about-img-background-right-inner-block-container:nth-child(3) {
    transition-delay: calc(var(--about-img-background-section-left-text-animation-delay) * 3);
}

.about-img-background-right-inner-block-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: var(--about-img-background-section-right-inner-block-border-width);
    height: var(--about-img-background-section-right-inner-block-border-height);
    background-color: var(--about-img-background-section-right-inner-block-border-color);
}

.about-img-background-right-block.about-hidden .about-img-background-right-inner-block-container {
    opacity: 0;
    transform: translateX(var(--about-img-background-section-right-inner-travel-distance));
}

.about-img-background-right-block.about-show .about-img-background-right-inner-block-container{
    opacity: 1;
    transform: translateX(0);
}

/* END */

/* our team section */

.about-team-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-team-title-section {
    text-align: center;
    margin-top: var(--about-team-title-top-margin);
}

.about-team-title {
    color: var(--about-team-title-color);
    font-size: var(--about-team-title-font-size);
    line-height: var(--about-team-title-line-height);
    letter-spacing: var(--about-team-title-letters-spacing);
}

.about-team-company-name {
    color: var(--about-team-company-name-color);
    font-size: var(--about-team-company-name-font-size);
    line-height: var(--about-team-company-name-line-height);
    letter-spacing: var(--about-team-company-name-letters-spacing);
}

.about-team-members-line {
    margin-top: var(--about-team-members-line-top-margin);
}

.about-team-member-block {
    display: flex;
    position: relative;
    padding-top: 3vh;
    padding-bottom: 3vh;
}

/* normal */
@media screen and (min-width: 1300px){
    .about-team-members-line {
        display: flex;
        justify-content: center;
    }

    .about-team-members-line:nth-child(2) .about-team-member-block:nth-child(1){
        transition-delay: calc(var(--about-team-members-block-transition-delay) * 4);
    }

    .about-team-members-line:nth-child(2) .about-team-member-block:nth-child(2){
        transition-delay: calc(var(--about-team-members-block-transition-delay) * 5);
    }

    .about-team-members-line:nth-child(2) .about-team-member-block:nth-child(3){
        transition-delay: calc(var(--about-team-members-block-transition-delay) * 6);
    }

    .about-team-member-block::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--about-team-members-vertical-line-width);
        height: var(--about-team-members-vertical-line-height);
        background-color: var(--about-team-members-vertical-line-color);
        transition-delay: var(--about-team-members-block-transition-delay);
    }

    .about-team-member-block {
        width: 33%;
        margin-left: var(--about-team-members-block-lr-margin);
        margin-right: var(--about-team-members-block-lr-margin);
    }

    .about-team-member-description {
        width: 59%;
    }

    .about-team-member-block:nth-child(2){
        transition-delay: calc(var(--about-team-members-block-transition-delay) * 2);
    }

    .about-team-member-block:nth-child(3){
        transition-delay: calc(var(--about-team-members-block-transition-delay) * 3);
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .about-team-title-section {
        margin-bottom: 5vh;
    }

    .about-team-members-line {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .about-team-member-block {
        width: 80%;
        margin: 5vh auto;
        padding: 0;
        gap: 5vw
    }

    .about-team-member-description {
        min-width: 60%;
    }

    .about-bot-spacing {
        display: none;
    }

    .about-team-member-img-container {
        min-width: 40%;
        margin: 0 !important;
    }
}

.about-team-member-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20%;
    min-height: 100%;
    margin-right: var(--about-team-members-img-right-margin);
    margin-left: var(--about-team-members-img-left-margin);
}

.about-team-member-img-container img {
    max-width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: var(--about-team-members-img-border-radius);
}

.about-team-member-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-team-member-name {
    color: var(--about-team-members-name-color);
    font-size: var(--about-team-members-name-font-size);
    line-height: var(--about-team-members-name-line-height);
    letter-spacing: var(--about-team-members-name-letters-spacing);
}

.about-team-member-description-spacing {
    height: var(--about-team-members-description-spacing);
}

.about-team-member-position {
    color: var(--about-team-members-position-color);
    font-size: var(--about-team-members-position-font-size);
    line-height: var(--about-team-members-position-line-height);
    letter-spacing: var(--about-team-members-position-letters-spacing);
}

/* END */
