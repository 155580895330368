:root {

    /* navigation background */
    --offer-navinagtion-background-color: #3c4856;
    --offer-navinagtion-background-height: 8vh;

    /* page background */
    --offer-background-gradient-shape: to bottom;
    --offer-background-images-opacity: 3%;
    --offer-background-images-size: 47%;
    --offer-background-images-margin: 15%;

    /* page lines as borders */
    --offer-line-as-border-prefab: 2px #4b3723 solid;

    /* page title */
    --offer-page-title-company-name-color: #ffffff;
    --offer-page-title-company-name-font-size: 2rem;
    --offer-page-title-company-name-letters-spacing: .05rem;
    --offer-page-title-company-name-line-height: .1rem;

    /* page title text */
    --offer-page-title-color: #ffffff;
    --offer-page-title-font-size: 4rem;
    --offer-page-title-letters-spacing: .05rem;
    --offer-page-title-line-height: 3.5rem;
    --offer-page-title-bottom-margin: 20vh;
    --offer-page-title-top-margin: 20vh;

    /* Sub-menu */
    --offer-submenu-item-width: 25vw;
    --offer-submenu-img-width: 8vw;
    --offer-submenu-item-margin: 10vh;
    --offer-submenu-text-font-size: 1.8vh;
    --offer-submenu-text-font-color: #ffffff;
    --offer-submenu-text-letters-spacing: .05rem;
    --offer-submenu-text-line-height: 3rem;

    /* connecting lines */
    --offer-connecting-lines-height: 20vh;

    /* text intersection animations */
    --offer-transition-speed: 1.5s;
    --offer-transition-delay: .2s;
    --offer-transition-x-offset: 3vw;

    /* tiles titles */
    --offer-tile-title-font-color: #ffffff;
    --offer-tile-title-font-size: 3vh;
    --offer-tile-title-line-height: 5vh;
    --offer-tile-title-letter-spacing: .1vw;
    --offer-tile-title-padding: 2vw;

    /* last tile title */
    --offer-last-tile-title-padding-right: 1rem;
    --offer-last-tile-title-padding-left: 3rem;

    /* tiles icons */
    --offer-tile-icon-margin-left: 3vw;
    --offer-tile-icon-margin-right: 2vw;

    /* tiles text */
    --offer-tile-text-container-padding-bottom: 20vh;
    --offer-tile-text-container-padding-to-side: 1rem;
    --offer-tile-text-font-color: #ffffff;
    --offer-tile-text-font-size: 1.7vh;
    --offer-tile-text-font-weight: lighter;
    --offer-tile-text-letter-spacing: .1vw;
    --offer-tile-text-line-height: 1.5rem;
    --offer-tile-text-padding-top: .5vh;
    --offer-tile-text-margin-bottom: 2.5vh;

}

/* general */

.show-line {
    opacity: 1;
}

.hide-line {
    opacity: 0;
}

.offer-hidden{
    opacity: 0;
    transition: all var(--offer-transition-speed);
    transition-delay: var(--offer-transition-delay);
}

.offer-show{
    opacity: 1;
}

/* END */

/* navigation background */

.offer-navigation-background {
    display: flex;
    min-height: var(--offer-navinagtion-background-height);
    background-color: var(--offer-navinagtion-background-color);
}

/* END */

/* page background */

.offer-divider {
    background-color: var(--offer-navinagtion-background-color);
    height: 1rem;
    width: 100%;
    display: block;
}

.offer{
    position: relative;
    width: 100vw;
    overflow: hidden;
}

.offer-gradient-background img{
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: -2;
    top: 0;
    left: 0;
}

.offer-background{
    opacity: var(--offer-background-images-opacity);
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;

    background-image:
            url('./../img/offer/implementation.svg'),
            url('./../img/offer/brainstorming.svg'),
            url('./../img/offer/growth.svg'),
            url('./../img/offer/live-chat.svg')
    ;
    background-position:
            var(--offer-background-images-margin) 5%,
            calc(100% - var(--offer-background-images-margin)) 35%,
            var(--offer-background-images-margin) 65%,
            calc(100% - var(--offer-background-images-margin)) 95%
    ;
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: var( --offer-background-images-size);
    z-index: 1;
    pointer-events: none;
}

.offer-page-container{
    z-index: 2;
}

/* END */

/* page title */

.offer-page-title-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--offer-page-title-bottom-margin);
    margin-top: var(--offer-page-title-top-margin);
}

.offer-page-title-company-name {
    color: var(--offer-page-title-company-name-color);
    font-size: var(--offer-page-title-company-name-font-size);
    letter-spacing: var(--offer-page-title-company-name-letters-spacing);
    line-height: var(--offer-page-title-company-name-line-height);
}

.offer-page-title {
    color: var(--offer-page-title-color);
    font-size: var(--offer-page-title-font-size);
    letter-spacing: var(--offer-page-title-letters-spacing);
    line-height: var(--offer-page-title-letters-spacing);
}

/* END */

/* Sub-menu */

.offer-submenu-item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offer-submenu-item-inner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offer-submenu-item-icon{
    height: auto;
    cursor: pointer;
}

.offer-submenu-item-text{
    font-size: var(--offer-submenu-text-font-size);
    color: var(--offer-submenu-text-font-color);
    letter-spacing: var(--offer-submenu-text-letters-spacing);
    line-height: var(--offer-submenu-text-line-height);
    cursor: pointer;
}

/* normal */
@media screen and (min-width: 1300px){
    .offer-submenu-section{
        display: flex;
    }

    .offer-submenu-item {
        width: var(--offer-submenu-item-width);
    }

    .offer-submenu-item-icon{
        width: var(--offer-submenu-img-width);
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .offer-submenu-section{
        display: grid;
        grid-template-columns: auto auto;
        justify-items: center;
        align-items: center;
        row-gap: 5vh;
    }

    .offer-submenu-item-text {
        text-align: center;
        line-height: 1.7rem;
    }

    .offer-submenu-item {
        width: 55%;
    }

    .offer-submenu-item-icon{
        width: calc(var(--offer-submenu-img-width) * 2);
    }
}

/* END */

/* connecting lines */

.offer-connecting-lines-container {
    width: 100%;
    height: var(--offer-connecting-lines-height);
    display: block;
}

/* END */

/* tiles section */

.offer-tiles-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offer-tile-container{
    display: flex;
    justify-content: center;
    width: 100%;

}

.offer-left-tile-title{
    color: var(--offer-tile-title-font-color);

    grid-column: 2;
    grid-row: 1;
    width: 100%;
    font-size: var(--offer-tile-title-font-size);
    line-height: var(--offer-tile-title-line-height);
    letter-spacing: var(--offer-tile-title-letter-spacing);
    border-bottom: var(--offer-line-as-border-prefab);
    border-left: var(--offer-line-as-border-prefab);
    padding-left: var(--offer-tile-title-padding);
}

.offer-left-tile-title.offer-hidden{
    opacity: 1 !important;

    /* I think smth is wrong with intersection observer */
    /*color: rgba(255, 255, 255, 0);*/
}

.offer-left-tile-title.offer-show{
    opacity: 1 !important;
    color: var(--offer-tile-title-font-color);
}

.offer-right-tile-title{
    color: var(--offer-tile-title-font-color);

    grid-column: 2;
    grid-row: 1;
    width: 100%;
    font-size: var(--offer-tile-title-font-size);
    line-height: var(--offer-tile-title-line-height);
    letter-spacing: var(--offer-tile-title-letter-spacing);
    border-bottom: var(--offer-line-as-border-prefab);
    border-right: var(--offer-line-as-border-prefab);
    padding-right: var(--offer-tile-title-padding);
    display: flex;
    justify-content: flex-end;
}

.offer-right-tile-title.offer-hidden{
    opacity: 1 !important;

    /* I think smth is wrong with intersection observer */
    /*color: rgba(255, 255, 255, 0);*/
}

.offer-right-tile-title.offer-show{
    opacity: 1 !important;
    color: var(--offer-tile-title-font-color);
}

.offer-left-tile-icon-container{
    grid-column: 1;
    grid-row: 2;
    display: flex;
    justify-content: flex-end;
}

.offer-right-tile-icon-container{
    grid-column: 3;
    grid-row: 2;
    display: flex;
    justify-content: flex-start;
}

.offer-tile-icon img{
    height: auto;
}

.offer-tile-inner-container{
    width: 100%;
    height: fit-content;
    display: grid;
}

.offer-tile-inner-container h2 {
    margin: 0;
}

/* normal */
@media screen and (min-width: 1300px){
    .offer-tile-icon img{
        width: var(--offer-submenu-img-width);
    }

    .offer-tile-icon{
        margin-right: var(--offer-tile-icon-margin-right);
        margin-left: var(--offer-tile-icon-margin-left);
    }

    .offer-tile-inner-container{
        grid-template-columns:
            calc(var(--offer-submenu-img-width) + var(--offer-tile-icon-margin-right) + var(--offer-tile-icon-margin-left) - 1px)
            calc(100% - var(--offer-submenu-img-width) * 2 - var(--offer-tile-icon-margin-left) * 2 - var(--offer-tile-icon-margin-right) * 2 - 2px)
            calc(var(--offer-submenu-img-width) + var(--offer-tile-icon-margin-right) + var(--offer-tile-icon-margin-left) - 1px);
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .offer-tile-icon img{
        display: none;

    }

    .offer-tile-inner-container{
        grid-template-columns: 5% 90% 5%;
    }
}

.offer-left-tile-text-container{
    grid-column: 2;
    grid-row: 2;
    border-right: var(--offer-line-as-border-prefab);
    width: 100%;
    padding-bottom: var(--offer-tile-text-container-padding-bottom);
    padding-left: var(--offer-tile-text-container-padding-to-side);
    padding-right: calc(var(--offer-tile-title-padding) - var(--offer-tile-text-container-padding-to-side));
    opacity: 1 !important;
}

.offer-right-tile-text-container{
    grid-column: 2;
    grid-row: 2;
    border-left: var(--offer-line-as-border-prefab);
    padding-bottom: var(--offer-tile-text-container-padding-bottom);
    padding-right: var(--offer-tile-text-container-padding-to-side);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    opacity: 1 !important;
}

.offer-right-tile-text-container .offer-tile-text {
    text-align: right;
}

.offer-tile-text{
    color: var(--offer-tile-text-font-color);
    font-size: var(--offer-tile-text-font-size);
    font-weight: var(--offer-tile-text-font-weight);
    letter-spacing: var(--offer-tile-text-letter-spacing);
    line-height: var(--offer-tile-text-line-height);
    padding-top: var(--offer-tile-text-padding-top);
    margin-bottom: var(--offer-tile-text-margin-bottom);
    transition: all var(--offer-transition-speed);
    transition-delay: var(--offer-transition-delay);
}

.offer-left-tile-text-container.offer-hidden .offer-tile-text{
    transform: translateX(var(--offer-transition-x-offset));
}

.offer-left-tile-text-container.offer-show .offer-tile-text{
    transform: translateX(0);
}

.offer-right-tile-text-container.offer-hidden .offer-tile-text{
    transform: translateX(calc(var(--offer-transition-x-offset) * -1));
}

.offer-right-tile-text-container.offer-show .offer-tile-text{
    transform: translateX(0);
}

#offer-last-tile-title{
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-left: calc(var(--offer-tile-title-padding) + 2px);
}

#offer-last-tile-title .offer-right-tile-title{
    width: fit-content;
    padding-right: var(--offer-last-tile-title-padding-right);
    padding-left: var(--offer-last-tile-title-padding-left);
}

#offer-last-tile-text-container{
    border: none;
}

/* END */
