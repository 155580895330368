

#hexagon-container2 {
    display: flex;
    justify-content: center;
}



.hex-row {
    display: flex;
}
